import { memo, forwardRef } from 'react';
import cc from 'classnames';
import { makeStyles } from 'hooks';
import { Grid as MaterialGrid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  flexItem: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexGrow: {
    flexGrow: 1
  }
}));

// eslint-disable-next-line react/display-name
export default memo(forwardRef(function Grid({ flexItem, flexGrow, className, item, ...other }, ref) {
  const classes = useStyles();
  const isItem = item || flexItem;
  return <MaterialGrid ref={ref} item={isItem} className={cc(className, flexItem && classes.flexItem, flexGrow && classes.flexGrow)} {...other} />;
}));