import { memo, forwardRef, useState, useRef } from 'react';
import ObjectID from 'bson-objectid';
import { makeStyles } from 'hooks';
import { Input } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  textarea: {
    whiteSpace: 'pre',
    overflowWrap: 'normal',
    overflow: 'auto'
  }
}));

// eslint-disable-next-line react/display-name
export default memo(forwardRef(function SelectText({ value, onChange, valueKey, nameKey, inputProps, ...other }, ref) {
  const classes = useStyles();
  valueKey = valueKey || 'value';
  nameKey = nameKey || 'name';
  const currentMap = new Map((value || []).map(i => [ i[nameKey], i[valueKey] ]));
  const originalItems = useRef(currentMap);

  // Use state to store input, so it doesn't jump around while you are typing
  // we will sync this to value behind the scenes
  const [ strValue, setStrValue ] = useState(() => Array.from(currentMap.keys(), k => k || '').join('\n'));

  // On a change set the text directly, but also update the value based on the items
  const handleChange = e => {
    const text = e.target.value;
    setStrValue(text);

    if (onChange) {
      const strItems = (text || '').split('\n').map(t => t.trim()).filter(t => t);
      // When we are mapping back, try and get from current set as well as from the original set, before finally creating a new value for it
      const itemMap = new Map(strItems.map(s => [ s, currentMap.get(s) || originalItems.current.get(s) || ObjectID().toHexString() ]));
      const items = Array.from(itemMap.entries(), e => ({ [nameKey]: e[0], [valueKey]: e[1] }));
      onChange(items);
    }
  };

  return <Input
    multiline
    rows={3}
    maxRows={5}
    inputRef={ref}
    inputProps={Object.assign({ className: classes.textarea }, inputProps || {})}
    {...other}
    value={strValue}
    onChange={handleChange}
  />;
}));