import { memo, useRef, useCallback, forwardRef, useEffect, useState, useId } from 'react';
import { useFocused, combineRefs } from 'hooks';
import { Paper, Unstable_TrapFocus as TrapFocus } from '@material-ui/core';
import Button from 'components/button';
import Tether from 'components/tether';
import { refsHaveFocus, findFirstFocusableChild } from 'utils/dom';
import media from 'services/media';

const windowConstraints = [{
  to: 'window',
  attachment: 'together',
  pin: true
}];

export const DropDown = memo(function DropDown({ open, children, attachment, targetAttachment, tetherClasses, direction, renderTarget, elementRef, elementClass, disableAutoClose, enableHover, showOnFocus, showOnFocusKey, openRefs, canOpen, ...other }) {
  const ignoreRef = useRef(null);
  const targetRef = useRef(null);
  const [ focused, setFocused ] = useFocused(true, ignoreRef, showOnFocus ? targetRef : null, ...(openRefs || []));
  const [ keyboard, setKeyboard ] = useState(false);
  const id = useId();

  const canTrapFocus = keyboard && !media.isTouchDevice;

  const handleToggle = useCallback(e => {
    setFocused(!focused);
    if (!focused && e.detail === 0) { setKeyboard(true); } // This is a keyboard event
    e.stopPropagation();
    e.preventDefault();
  }, [ focused, setFocused ]);

  const handleClick = useCallback(e => {
    if (!disableAutoClose) { setFocused(false); }
    setKeyboard(false);
    e.stopPropagation();
  }, [ disableAutoClose, setFocused ]);

  const handleKeyDown = useCallback(e => {
    switch (e.key) {
      case 'Escape':
        setFocused(false);
        setKeyboard(false);
        e.preventDefault();
        e.stopPropagation();
        break;
    }
  }, [ setFocused ]);

  const timeoutRef = useRef(null);
  const cTimeout = useCallback(() => {
    setKeyboard(false);
    if (timeoutRef.current) { clearTimeout(timeoutRef.current); timeoutRef.current = null; }
  }, []);

  const onMouseOver = useCallback(() => {
    cTimeout();
    setFocused(true);
  }, [ cTimeout, setFocused ]);
  const handleMouseOver = enableHover ? onMouseOver : undefined;

  const onMouseOut = useCallback(() => {
    cTimeout();
    timeoutRef.current = setTimeout(() => {
      setFocused(false);
    }, 300);
  }, [ cTimeout, setFocused ]);
  const handleMouseOut = enableHover ? onMouseOut : undefined;

  // When we show on focus, need to be able to access it via keyboard
  const onTargetKeyDown = useCallback(e => {
    const focusKey = showOnFocusKey || 'ArrowDown';
    if (e.key === focusKey) {
      setKeyboard(true);
      setFocused(true);
      e.stopPropagation();
      e.preventDefault();
    }
  }, [ showOnFocusKey, setFocused ]);
  const handleTargetKeyDown = showOnFocus ? onTargetKeyDown : undefined;

  // If we lose focus, we also lose keyboard
  useEffect(() => {
    if (!focused) { setKeyboard(false); }
  }, [ focused ]);

  useEffect(() => {
    if (canTrapFocus) {
      const element = findFirstFocusableChild(ignoreRef.current, 1);
      element?.focus();
    }
  }, [ canTrapFocus ]);

  const dir = direction || 'left';
  const htmlProps = {
    'aria-haspopup': 'menu',
    'aria-expanded': focused,
    'aria-owns': id,
    onMouseOver: handleMouseOver,
    onMouseOut: handleMouseOut,
    onKeyDown: handleTargetKeyDown
  };

  return <Tether
    open={(canOpen || canOpen == null) ? focused : false}
    attachment={attachment || `top ${dir}`}
    targetAttachment={targetAttachment || `bottom ${dir}`}
    constraints={windowConstraints} // Try any make sure the dropdown is always pinned to the visible window area
    classes={tetherClasses}
    renderTarget={ref => renderTarget(combineRefs(ref, targetRef), handleToggle, focused, htmlProps)}
    renderElement={ref =>
      <div
        id={id}
        ref={combineRefs(ref, ignoreRef, elementRef)}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        className={elementClass}
      >
        <TrapFocus open={canTrapFocus} getDoc={() => document} isEnabled={() => !openRefs || !refsHaveFocus(openRefs)}>
          { children }
        </TrapFocus>
      </div>
    }
    {...other}
  />;
});

// eslint-disable-next-line react/display-name
export default memo(forwardRef(function DropDownButton({ children, attachment, targetAttachment, direction, buttonContent, tetherClasses, elementRef, elementClass, disableAutoClose, enableHover, ...other }, outerRef) {
  return <DropDown
    disableAutoClose={disableAutoClose}
    attachment={attachment}
    targetAttachment={targetAttachment}
    direction={direction}
    tetherClasses={tetherClasses}
    elementRef={elementRef}
    elementClass={elementClass}
    enableHover={enableHover}
    renderTarget={(ref, handleClick, f, htmlProps) => <Button ref={combineRefs(ref, outerRef)} onClick={handleClick} {...htmlProps} {...other}>{ buttonContent }</Button>}
  >
    <Paper>
      { children }
    </Paper>
  </DropDown>;
}));