import { memo, useRef, useCallback } from 'react';
import TetherComponent from 'react-tether';
import { useFocused, combineRefs } from 'hooks';

// Just using tether component directly...
export default memo(function Tether({ open, renderTarget, renderElement, openRefs, ...other }) {
  const focusEnabled = open === undefined;
  const targetRef = useRef(null);
  const pickerRef = useRef(null);
  const [ focused ] = useFocused(focusEnabled, targetRef, pickerRef, ...(openRefs || []));

  const isOpen = focusEnabled ? focused : open;

  const rt = useCallback(ref => renderTarget(combineRefs(ref, targetRef)), [ renderTarget ]);
  const re = useCallback(ref => isOpen && renderElement(combineRefs(ref, pickerRef)), [ isOpen, renderElement ]);

  return <TetherComponent renderTarget={rt} renderElement={re} {...other} />;
});