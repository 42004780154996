import { useEffect } from 'react';
import { observer } from 'decorators';
import { makeStyles } from 'hooks';
import { Snackbar as MuiSnackbar, SnackbarContent } from '@material-ui/core';
import media from 'services/media';
import pageTracking from 'services/pageTracking';

const useStyles = makeStyles(theme => ({
  anchorOriginTopRight: {
    [theme.breakpoints.up('sm')]: {
      top: theme.spacing(9)
    },
    [theme.breakpoints.only('sm')]: {
      left: theme.spacing(7),
      right: theme.spacing(1)
    }
  },
  root: {
    flexWrap: 'nowrap',
    backgroundColor: theme.palette.primary.dark
  },
  message: {
    color: theme.palette.getContrastText(theme.palette.primary.dark)
  }
}));

export default observer(function Snackbar({ message, action, ContentProps, ...props }) {
  const classes = useStyles();

  // Reset on deconstruct
  useEffect(() => () => moveIntercom(0), []);

  const options = Object.assign({
    TransitionProps: {
      onEntering: () => moveIntercom(60),
      onExiting: () => moveIntercom(0)
    },
    anchorOrigin: media.is('xs') ? { vertical: 'bottom', horizontal: 'center' } : { vertical: 'top', horizontal: 'right' }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, props);

  return <MuiSnackbar classes={classes} {...options}>
    <SnackbarContent message={message} action={action} {...ContentProps} classes={{ root: classes.root, message: classes.message }} />
  </MuiSnackbar>;
});

function moveIntercom(pos) {
  if (!media.is('xs')) { return; }

  const intercom = pageTracking.getSupportDom();
  if (!intercom) { return; }

  intercom.style.bottom = pos + 'px';
};