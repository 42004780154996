import moment from 'moment';
import { observable, action, computed, makeObservable, flow } from 'decorators';
import Form from 'components/form';
import { ValidationError } from 'errors';
import baseApi from 'utils/baseApi';
import auth from 'services/auth';
import userService from 'services/data/user';
import pageTracking from 'services/pageTracking';
import log from 'services/log';
import { shortDate } from 'utils/date';
import { getSubscriptionText, getDedicatedNumbersPricing, getRepugenPricing } from './utils';

export default class PlanStore {
  @observable step = 0;
  @observable planView = 0;
  @observable useExistingCard = true;
  @observable isCompleting = false;

  @observable _couponDescription = null;

  constructor({ credit, completeSubscription, onClose, ...props }) {
    makeObservable(this);
    this.credit = credit;
    this.planViewItems = planViewItems;
    this._completeSubscription = completeSubscription;
    this._onClose = onClose;

    this.form = new Form(this._createForm(props));
    this.form.validate('hours', { showErrors: true });
    pageTracking.initAffiliate();
  }

  @computed get details() {
    return userService.orgPaymentDetails.value;
  }

  @computed get hasPaid() {
    return !!this.details?.firstPayment;
  }

  @computed get nextPayment() {
    if (!this.details) { return null; }
    const { nextPayment } = this.details;
    const isImmediate = moment(nextPayment).isBefore();
    return (isImmediate || (this.hasPaid && !auth.user.hasSubscription)) ? 'Immediate' : (this.hasPaid ? 'Immediate (Pro Rata)' : shortDate(nextPayment, { incDay: true }));
  }

  @computed get number() {
    let hours = this.form?.$('hours').value || 0;
    if (hours === 0) { hours = 1; }
    return Math.floor((hours - 1) / 25) + 1;
  }

  @computed get subscription() {
    const type = this.form.$('type').value;
    const isAnnual = this.form.$('isAnnual').value;
    return getSubscriptionText(this.number, type, isAnnual);
  }

  @computed get dedicatedNumbersPricing() {
    const { phoneNumberPrice } = this.details;
    const isAnnual = this.form.$('isAnnual').value;
    return getDedicatedNumbersPricing(phoneNumberPrice, isAnnual);
  }

  @computed get repugenPricing() {
    const { repugenPackage, repugenNumber } = this.details;
    const isAnnual = this.form.$('isAnnual').value;
    return getRepugenPricing(repugenPackage, repugenNumber, isAnnual);
  }

  @computed get couponDescription() {
    return this._couponDescription ? ('You will receive a ' + this._couponDescription) : '';
  }

  @computed get couponNotice() {
    if (!this.couponDescription || !this.hasPaid) { return null; }
    return `* Discount applies from first payment, which was ${shortDate(this.details.firstPayment)}.`;
  }

  @computed get billingCard() {
    const { hasCreditCard, last4 } = this.details;
    return hasCreditCard ? ('XXXX-XXXX-XXXX-' + last4) : null;
  }

  dispose() {}

  contactUs() {
    userService.openSupport(`Enterprise Plan${'\n'}${'\n'}Hi,${'\n'}I am interested in upgrading to the Enterprise Plan.`);
  }

  selectPlan(type) {
    this.form.validate('hours', { showErrors: true })
      .then(action(({ isValid }) => {
        if (!isValid) { return null; }

        this.form.$('type').set('value', type);
        this.step = 1;
        return null;
      }))
      .catch(() => {})
      .done();
  }

  close() {
    this._onClose();
  }

  @action complete(e) {
    this.isCompleting = true;
    this.form.submitPromise(e)
      .then(() => {
        const v = this.form.values();

        return this._completeSubscription({
          type: v.type,
          annual: v.isAnnual,
          number: this.number,
          coupon: v.coupon,
          referralCode: v.referralCode,
          affiliateId: pageTracking.getAffiliateId(),
          useExistingCard: this.billingCard && this.useExistingCard
        });
      })
      .then(action(shouldClose => {
        if (shouldClose) {
          this._onClose();
          this.isCompleting = false;
        }
        return null;
      }))
      .catch(ValidationError, action(() => (this.isCompleting = false)))
      .catch(action(err => {
        log.catchAndNotify(err);
        this.isCompleting = false;
      }))
      .done();
  }

  _createForm({ isAnnual, type, number, coupon, couponDesc }) {
    this._couponDescription = couponDesc;

    const fields = [ 'isAnnual', 'type', 'hours', 'coupon', 'referralCode' ];
    const labels = {
      coupon: 'Coupon Code',
      referralCode: 'Referral Code'
    };

    const types = {
      hours: 'number'
    };

    const values = {
      isAnnual: !!isAnnual,
      type,
      hours: number * 25,
      coupon: coupon || '',
      referralCode: ''
    };

    const handlers = {
      hours: {
        onChange: field => e => {
          let hours = e.target.value;
          hours = hours ? Math.floor(Math.abs(Number(hours))) : hours;
          field.set('value', hours);
        }
      }
    };

    const validators = {
      hours: [ () => this._validateHours() ],
      coupon: [ ({ field }) => this._validateCoupon(field.value) ],
      referralCode: [ ({ field }) => this._validateReferral(field.value) ]
    };

    const extra = {
      hours: {
        // eslint-disable-next-line react/display-name
        calcFields: () => ({
          helperText: <span>You have selected between <strong>{(this.number - 1) * 25} - {this.number * 25} hours</strong> of appointments which is equivalent to <strong>{this.number} subscriptions</strong>.</span>
        })
      },
      coupon: {
        calcFields: () => ({
          helperText: this.couponDescription || ' '
        })
      }
    };

    return { fields, labels, types, values, handlers, validators, extra };
  }

  _validateHours() {
    if (!this.form) { return [ true, '' ]; }
    const { totalOrgs, needsMinSubscriptions } = this.details;
    if (needsMinSubscriptions != null && this.number < needsMinSubscriptions) {
      const hours = (needsMinSubscriptions - 1) * 25 + 1;
      return [ false, `Based on your account's recorded number of client-related appointments, your subscription volume needs to be at least ${hours} hours/week.` ];
    }

    const valid = this.number >= totalOrgs;
    return [ valid, `Due to the number of linked ${auth.lang['org.org']}s you need at least ${totalOrgs} subscriptions (${totalOrgs * 25} hours)` ];
  }

  @flow *_validateCoupon(coupon) {
    if (this.hasPaid) { return [ true, '' ]; }
    coupon = (coupon || '').trim();
    if (!coupon) { return [ true, '' ]; }

    try {
      const res = yield baseApi.get(`coupon/check/${encodeURIComponent(coupon)}`);
      this._couponDescription = res.data?.description || '';
      return [ !!res.data, 'Not a valid coupon' ];
    } catch {
      this._couponDescription = null;
      return [ false, 'Unknown error validating coupon' ];
    }
  }

  @flow *_validateReferral(refCode) {
    if (this.hasPaid) { return [ true, '' ]; }
    refCode = (refCode || '').trim();
    if (!refCode) { return [ true, '' ]; }

    try {
      const res = yield baseApi.get(`coupon/referralCheck/${encodeURIComponent(refCode)}`);
      return [ !!res.data.valid, 'Not a valid referral code' ];
    } catch {
      return [ false, 'Unknown error validating referral code' ];
    }
  }
}

const planViewItems = [
  {
    value: 'standard',
    name: 'Standard',
    price: 27,
    features: [
      'Appointment Management',
      'Invoicing, Superbills & Insurance Claims',
      'Chart Notes & Template Library',
      'E-signing & Online Forms',
      'Fullscript Integration',
      'Secure Client Messaging* & Faxing**'
    ]
  },
  {
    value: 'pro',
    name: 'Virtual Practice',
    price: 47,
    features: [
      'All Standard Features',
      'HIPAA Compliant Telehealth',
      'Group Video Conferencing',
      'Online Scheduling Widget',
      'Integrated Food Logging & Fitness App',
      'Credit Card Processing'
    ]
  },
  {
    value: 'enterprise',
    name: 'Enterprise',
    price: null,
    features: [
      'For 20+ Subscriptions',
      'All Features',
      'Special Volume Discounts',
      'Customized Document Templates',
      'White Labeling Available',
      'And More!'
    ]
  }
];