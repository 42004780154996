import { Fragment, useEffect, useRef } from 'react';
import { observer } from 'decorators';
import { makeStyles } from 'hooks';
import { CssBaseline, Grid, Typography, IconButton } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Snackbar from 'components/snackbar';
import Modal from 'components/modal';
import Button from 'components/button';
import NavLink from 'components/navLink';
import ErrorBoundary from 'components/errorBoundary';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import toastr from 'services/toastr';
import dialog from 'services/dialog';
import { VideoBlocksManageButton } from 'pages/organization/subscription/videoblocks';

import themeService from 'theme';

import { Close } from 'mdi-material-ui';

import './theme/fonts.scss';

const useStyles = makeStyles(theme => ({
  infoBar: {
    backgroundColor: theme.palette.grey[300]
  },
  dialog: {
    zIndex: (theme.zIndex.modal + 1) + ' !important'
  },
  '@global': {
    html: {
      fontFamily: theme.typography.fontFamily,
      height: '100%',
      overflow: 'auto'
    },
    body: {
      height: '100%',
      width: '100%',
      fontWeight: 400,
      '& #intercom-container, & .intercom-lightweight-app': {
        zIndex: `${theme.zIndex.appBar + 3} !important`,
        transition: theme.transitions.create('bottom', { duration: theme.transitions.duration.complex }),
        position: 'fixed',
        bottom: 0,
        right: 0,
        '& .intercom-launcher-frame': {
          right: `${theme.spacing(1)}px !important`,
          bottom: `${theme.spacing(1)}px !important`
        },
        '& .intercom-messenger-frame': {
          right: `${theme.spacing(1)}px !important`,
          bottom: `${theme.spacing(9.5)}px !important`
        }
      },
      '& .intercom-launcher': {
        right: `${theme.spacing(1)}px !important`,
        bottom: `${theme.spacing(1)}px !important`,
        zIndex: `${theme.zIndex.appBar + 3} !important`
      }
    },
    'ul, ul ul, ul ul ul': {
      listStyleType: 'disc'
    },
    'ul > li > ul': {
      listStyleType: 'circle'
    },
    'ul > li > ul > li > ul': {
      listStyleType: 'square'
    },
    'ol, ol ol, ol ol ol': {
      listStyleType: 'decimal'
    },
    'ol > li > ol': {
      listStyleType: 'lower-alpha'
    },
    'ol > li > ol > li > ol': {
      listStyleType: 'lower-roman'
    },
    a: {
      textDecoration: 'none',
      color: theme.isBranded ? theme.palette.primary.main : 'rgb(85, 26, 139)'
    },
    '.tether-element': {
      zIndex: theme.zIndex.modal
    },
    '.tether-pinned': {
      maxHeight: '95%'
    },
    '.hidePrint': {
      '@media print': {
        display: 'none'
      }
    }
  }
}));

export const ErrorRelatedAction = observer(function ErrorRelatedAction({ linkName }) {
  if (linkName) {
    const parts = linkName.split('.');
    if (parts[0] === 'action') {
      switch (parts[1]) {
        case 'purchaseVideoblocks':
          return <VideoBlocksManageButton color="primary">Manage</VideoBlocksManageButton>;
        default:
          return null;
      };
    } else {
      return <NavLink name={linkName}>View</NavLink>;
    }
  }
  return null;
});

// Need a wrapper so we can apply the themes, as the app view has some styles!
export default observer(function AppViewWrapper({ children, theme }) {
  return <MuiThemeProvider theme={theme || themeService.defaultTheme}>
    <ErrorBoundary>
      <AppView>
        { children }
      </AppView>
    </ErrorBoundary>
  </MuiThemeProvider>;
});

const AppView = observer(function AppView({ children }) {
  useStyles(); // This is to import the global styles

  const brandName = themeService.brandName;
  const version = useRef(null);
  useEffect(() => {
    // Check for a newer version
    // Note we use XMLHttpRequest not fetch to support older browsers
    let timeoutCheck = null;
    let currentPromise = null;
    const checkVersion = () => {
      if (timeoutCheck) { clearTimeout(timeoutCheck); timeoutCheck = null; }

      currentPromise = new Promise((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.addEventListener('load', () => {
          resolve(req.responseText);
        });
        req.addEventListener('error', reject);
        req.addEventListener('abort', reject);
        req.addEventListener('timeout', reject);
        req.open('GET', '/version');
        req.send();
      })
        .then(v => {
          const current = version.current;
          version.current = v;
          if (current && current !== v) {
            return dialog.show({
              title: 'Update Available',
              description: `A newer version of ${brandName} is available. We recommend that you save your work before reloading the page to update.`,
              primaryButton: 'Reload Now',
              secondaryButton: 'Ignore'
            })
              .then(() => {
                location.reload();
              });
          }
        })
        .catch(() => {})
        .then(() => {
          // Check version every 10 mins
          timeoutCheck = setTimeout(() => checkVersion(), 10 * 60 * 1000);
        })
        .done();
    };

    checkVersion();
    return () => {
      if (currentPromise) { currentPromise.cancel(); currentPromise = null; }
      if (timeoutCheck) { clearTimeout(timeoutCheck); timeoutCheck = null; }
    };
  }, [ brandName ]);

  return <HelmetProvider>
    <CssBaseline />
    <Helmet defaultTitle={brandName} titleTemplate={`%s | ${brandName}`} />
    <ToastView />
    <DialogView />
    { !themeService.loadingBrand && children }
  </HelmetProvider>;
});

const handleToastClear = () => toastr.clear();
const handleDeleteClear = () => toastr.clearDelete();
const handleCompleteDelete = () => toastr.completeDelete().done();
const ToastView = observer(function ToastView() {
  const classes = useStyles();
  const t = toastr.currentToast;
  const d = toastr.currentDelete;

  return <Fragment>
    { !!t &&
      <Snackbar
        open
        action={<IconButton onClick={handleToastClear} aria-label="Close"><Close /></IconButton>}
        message={t.message && <Typography color={t.color} component="span"><t.icon /> {t.message.replace(/\.+$/g, '')}. <ErrorRelatedAction linkName={t.linkName} /></Typography>}
        autoHideDuration={60000}
        onClose={handleToastClear}
        ContentProps={{ className: classes.infoBar }}
      />
    }
    { !!d &&
      <Snackbar
        open
        message={d.message}
        action={[
          <Button key="undo" size="small" color="secondary" onClick={handleCompleteDelete}>
            Undo
          </Button>,
          <Button icon key="close" color="inherit" onClick={handleDeleteClear}>
            <Close />
          </Button>
        ]}
      />
    }
  </Fragment>;
});

const handleDialogCancel = () => dialog.cancel();
const handleDialogSuccess = () => dialog.success();
const handleTertiary = () => dialog.tertiary();
const DialogView = observer(function DialogView() {
  const classes = useStyles();
  const { open, title, description, isDescPre, secondaryButton, primaryButton, tertiaryButton, hideSecondary } = dialog;
  return <Modal open={open} disableBackdropClick label={title} className={classes.dialog}>
    <Typography paragraph style={isDescPre ? { whiteSpace: 'pre-wrap' } : undefined}>
      { description }
    </Typography>
    <Grid container spacing={2}>
      { !!tertiaryButton &&
        <Grid item>
          <Button color="secondary" onClick={handleTertiary}>{tertiaryButton}</Button>
        </Grid>
      }
      <Grid item xs />
      { !hideSecondary &&
        <Grid item>
          <Button onClick={handleDialogCancel}>{secondaryButton || 'Cancel'}</Button>
        </Grid>
      }
      <Grid item>
        <Button color="primary" onClick={handleDialogSuccess}>{primaryButton || 'Continue'}</Button>
      </Grid>
    </Grid>
  </Modal>;
});